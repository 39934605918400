<template>
  <div>
    <h4 class="mx-auto mt-3 mb-4 fs-5">
      確認訂單資訊
    </h4>
    <div class="mx-lg-5 px-3 px-md-4 py-5 shadow rounded-3">
      <div class="row col-12 col-md-11 align-items-center mx-auto mb-5 mb-md-0 py-2 px-md-0">
        <div class="text-start col-12 col-md-4 px-0">
          商品名稱
        </div>
        <div class="text-start col-12 col-md-3 px-0">
          規格
        </div>
        <div class="d-block d-md-none w-100 my-2" />
        <div class="text-md-end text-center col flex-grow-1 px-0">
          售價
        </div>
        <div class="text-md-end text-center col flex-grow-1 px-0">
          數量
        </div>
        <div class="text-md-end text-center col flex-grow-1 px-0">
          合計
        </div>
      </div>
      <hr class="col-12 col-md-11 mx-auto">
      <div
        v-for="(pro, index) in carts"
        :key="index"
        class="row col-12 col-md-11 align-items-center mb-5 my-md-2 mx-auto py-2 px-md-0"
      >
        <div class="text-start col-12 col-md-4 px-0">
          {{ pro.name }}
          <input
            type="text"
            :value="pro.cartID"
            :name="'carts[' + index + '].cartID'"
            class="d-none"
          >
          <input
            type="text"
            :value="pro.name"
            :name="'carts[' + index + '].name'"
            class="d-none"
          >
        </div>
        <div class="text-start col-12 col-md-3 px-0">
          <div class="text-start">
            {{ pro.spec1Name?pro.spec1Name + '：' : '' }}
            {{ pro.spec1Value?pro.spec1Value : '' }}
          </div>
          <div
            v-if="pro.spec2Value"
            class="text-start"
          >
            {{ pro.spec2Name?pro.spec2Name + '：' : '' }}
            {{ pro.spec2Value?pro.spec2Value : '' }}
          </div>
        </div>
        <div class="d-block d-md-none w-100 my-2" />
        <div class="text-md-end text-center col flex-grow-1 px-0">
          {{ getThousands(pro.fixedPrice) }}
          <input
            type="number"
            :value="pro.fixedPrice"
            :name="'carts[' + index + '].fixedPrice'"
            class="d-none"
          >
          <!--                             <input type="number" :value="pro.price * 0.05" :name="'carts[' + index + '].tax'" class="d-none" /> -->
          <!--                             <input type="number" :value="pro.price * 1.05" :name="'carts[' + index + '].taxInPrice'" class="d-none" /> -->
          <input
            type="number"
            :value="pro.fixedPrice * pro.qty"
            :name="'carts[' + index + '].amount'"
            class="d-none"
          >
        </div>
        <div class="text-md-end text-center col flex-grow-1 px-0">
          {{ getThousands(pro.qty) }}
        </div>
        <!--                         <div class="text-center  text-danger flex-grow-1 px-3"> -->
        <!--                             +{{getThousands(pro.counpon)}} -->
        <!--                             <input type="number" :value="pro.counpon" :name="'carts[' + index + '].counpon'" class="d-none" /> -->
        <!--                         </div> -->
        <div class="text-md-end text-center col flex-grow-1 px-0">
          {{ getThousands(pro.fixedPrice * pro.qty) }}
          <input
            type="number"
            :value="pro.qty"
            :name="'carts[' + index + '].qty'"
            class="d-none"
          >
        </div>
      </div>
      <hr class="col-12 col-md-11 mx-auto">

      <div class="row col-12 col-md-11 py-2 mx-auto my-3 px-md-0">
        <span class="col-6 offset-md-4 px-0 text-start">合計</span>
        <span class="col text-end px-0">
          {{ getThousands(totalAmountWithTax) }}
          <input
            type="number"
            :value="totalAmountWithTax"
            name="amount"
            class="d-none"
          >
        </span>
      </div>

      <div class="row col-12 col-md-11 py-2 mx-auto my-3 px-md-0">
        <span class="col-6 offset-md-4 px-0 text-start">運費</span>
        <span class="col text-end px-0">
          {{ getThousands(shipFee) }}
          <input
            type="number"
            :value="shipFee"
            name="shipFee"
            class="d-none"
          >
        </span>
      </div>

      <div class="row col-12 col-md-11 py-2 mx-auto my-3 px-md-0">
        <span class="col-6 offset-md-4 px-0 text-start">應付金額</span>
        <span class="col text-end px-0">
          {{ getThousands(totalAmountWithTax + shipFee) }}
          <input
            type="number"
            :value="totalAmountWithTax + shipFee"
            name="payAmt"
            class="d-none"
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'CheckoutProductList',
  components: {

  },
  setup () {
    const { getters, commit } = useStore()
    const { push } = useRouter()

    const checkoutCart = computed(() => getters['cart/getCheckoutCart'])

    const carts = checkoutCart.value?.carts || []
    const totalAmountWithTax = checkoutCart.value?.totalAmountWithTax || []

    const shipFee = inject('shipFee')

    const getThousands = (num) => {
      return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    }

    if (!checkoutCart.value || carts.length === 0) {
      push('/cart')
      commit('cart/setCheckoutCart', { data: [] })
    }

    return {
      carts, getThousands, totalAmountWithTax, shipFee
    }
  }
}
</script>
<style lang="scss" module>

</style>
