<template>
  <div class="container-lg">
    <div class="row">
      <suspense>
        <template #default>
          <ProductList />
        </template>
        <template #fallback>
          <div />
        </template>
      </suspense>

      <suspense>
        <template #default>
          <ShipperDetail />
        </template>
        <template #fallback>
          <div />
        </template>
      </suspense>

      <div class="col-12 col-md-11 d-flex justify-content-center mx-auto my-5">
        <div class="mx-2">
          <button
            type="button"
            class="btn btn-outline-primary rounded-0"
            @click="back()"
          >
            上一頁
          </button>
        </div>
        <div class="mx-2">
          <button
            class="btn btn-primary text-white rounded-0"
            type="button"
            @click="createPayment"
          >
            結帳
          </button>
        </div>
      </div>
    </div>

    <form
      id="CheckoutForm"
      class="d-none"
      :action="paywayUrl"
      method="post"
    >
      <input
        v-for="field in payloadFields"
        :key="field"
        :name="field"
        type="text"
        :value="payload[field]"
      >
      <div
        v-for="(cart, cartIndex) in carts"
        :key="cartIndex"
      >
        <input
          v-for="field in payloadCartFields"
          :key="field"
          :name="field"
          type="text"
          :value="payloadCart[field]"
        >
      </div>
    </form>
  </div>
</template>
<script>
import { computed, inject, ref, provide, nextTick } from 'vue'
import { useStore } from 'vuex'
import ProductList from '@/components/front/checkout/ProductList.vue'
import ShipperDetail from '@/components/front/checkout/ShipperDetail.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'Checkout',
  components: {
    ProductList, ShipperDetail
  },
  async setup () {
    const { back } = useRouter()
    const { dispatch, getters } = useStore()

    const memberID = computed(() => getters['member/getMemberID'])
    const checkoutCart = computed(() => getters['cart/getCheckoutCart'])
    const checkoutIDList = computed(() => getters['cart/getCheckoutIDList'])
    const orderID = computed(() => getters['order/getOrderID'])

    const carts = checkoutCart.value?.carts || []
    const totalAmountWithTax = checkoutCart.value?.totalAmountWithTax || []

    const setAlert = inject('setAlert')

    let v$ = null
    const setv$ = (value) => {
      v$ = value
    }
    provide('setv$', setv$)

    const selectedPayway = ref({})
    const selectedShipService = ref({})
    const setSelectedPayway = (item) => {
      selectedPayway.value = item
    }
    const setSelectedShipService = (item) => {
      selectedShipService.value = item
    }
    const shipFee = computed(() => selectedShipService.value?.fixedFee || 0)
    provide('shipFee', shipFee)
    provide('setSelectedPayway', setSelectedPayway)
    provide('setSelectedShipService', setSelectedShipService)

    const getThousands = (num) => {
      return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    }

    const cartFields = [
      'cartID',
      'description',
      'fixedPrice',
      'hasCw',
      'isActive',
      'mainImg',
      'memberID',
      'name',
      'productID',
      'qty',
      'spec1ID',
      'spec1Name',
      'spec1Value',
      'spec2ID',
      'spec2Name',
      'spec2Value',
      'specID',
      'storageQty',
      'suiteID'
    ]
    const payloadCartFields = ref([])
    const payloadCart = ref({})
    const setPayloadCart = (carts) => {
      payloadCart.value = {}
      payloadCartFields.value = []
      carts.forEach((cart, index) => {
        cartFields.forEach((field) => {
          payloadCartFields.value.push(`carts[${index}].${field}`)
          payloadCart.value[`carts[${index}].${field}`] = cart?.[field] || ''
        })
      })
    }

    const payloadFields = [
      'orderID',
      'clientID',
      'amount',
      'number',
      'cell',
      'email',
      'memberID',
      'memo',
      'payAmt',
      'payway',
      'paywayID',
      'recipient',
      'serviceID',
      'shipAddr',
      'shipFee',
      'webPageReturnUrl'
    ]
    const payload = ref({})
    const paywayUrl = ref('')

    const readCheckoutCart = async () => {
      const payload = {
        idList: checkoutIDList.value,
        offset: 0,
        pageSize: 20
      }

      // 刪除
      try {
        await dispatch('cart/readCheckoutCart', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    // 前往第三方支付
    const createPayment = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        await readCheckoutCart()
        const hasStorage = carts.every((item) => {
          return item.storageQty >= item.qty
        })

        if (hasStorage) {
          try {
            if (v$.value.saveRecipient.$model) {
              const recipientPayload = {
                memberID: memberID.value,
                rcptAddress: v$.value.shipAddr.$model,
                rcptCell: v$.value.cell.$model,
                // rcptCounty: v$.value.shipCounty.$model,
                rcptMail: v$.value.email.$model,
                rcptName: v$.value.recipient.$model
                // rcptZipcode: v$.value.rcptZipcode.$model
              }
              await dispatch('member/updateRecipientRecord', { payload: recipientPayload })
            } else {
              const recipientPayload = {
                memberID: memberID.value
              }
              await dispatch('member/deleteRecipientRecord', { payload: recipientPayload })
            }
            payload.value = {
              clientID: process.env.VUE_APP_clientID,
              orderID: orderID.value,
              amount: totalAmountWithTax,
              number: carts.length,
              cell: v$.value.cell.$model,
              // 折價券編號
              // couponID: v$.value.couponID.$model,
              // 折價券折抵金額
              // couponUse: v$.value.couponUse.$model,
              email: v$.value.email.$model,
              memberID: memberID.value,
              memo: v$.value.memo.$model,
              // 訂單編號
              // orderID: v$.value.orderID.$model,
              // 應支付金額
              payAmt: totalAmountWithTax + shipFee.value,
              payway: selectedPayway.value.payway,
              paywayID: v$.value.paywayID.$model,
              recipient: v$.value.recipient.$model,
              serviceID: v$.value.serviceID.$model,
              shipAddr: v$.value.shipAddr.$model,
              // 配送縣市
              // shipCounty: v$.value.shipCounty.$model,
              shipFee: shipFee.value,
              // 配送狀態編號
              // shipID: selectedShipService.value?.serviceID,
              // 配送郵遞區號
              // shipZipcode: v$.value.shipZipcode.$model,
              // 訂單狀態編號
              // statusID: v$.value.statusID.$model,
              webPageReturnUrl: 'https://demo1.omniec.com.tw/'
            }

            setPayloadCart(carts)
            switch (selectedPayway.value.trader) {
              case 'ECPay': {
                paywayUrl.value = process.env.VUE_APP_API + '/web/ecpay'
                break
              }
              case 'NBPay': {
                paywayUrl.value = process.env.VUE_APP_API + '/web/nbpay'
                break
              }
              case 'Card0': {
                paywayUrl.value = process.env.VUE_APP_API + '/web/card0'
                break
              }
              default:
                setAlert(true, false, '付款出錯，請聯絡網站管理員')
            }
            nextTick(() => {
              document.querySelector('#CheckoutForm').submit()
            })
          } catch (error) {
            console.log(error)
            setAlert(true, false, error)
          }
        } else {
          setAlert(true, false, '部分商品已售完，請重新選購')
          back()
        }
      }
    }

    const readPayways = async () => {
      const payload = {}

      try {
        await dispatch('payment/readPayways', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    const readShipServices = async () => {
      const payload = {}

      try {
        await dispatch('order/readShipServices', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    const readOrderID = async () => {
      const payload = {}

      try {
        await dispatch('order/readOrderID', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    const readRecipientRecord = async () => {
      const payload = {
        memberID: memberID.value
      }

      try {
        await dispatch('member/readRecipientRecord', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    await Promise.all([readPayways(), readShipServices(), readOrderID(), readRecipientRecord()])

    return {
      getThousands, createPayment, back, paywayUrl, carts, payload, payloadCart, payloadCartFields, payloadFields
    }
  }
}
</script>
<style lang="scss" module>

</style>
